'use strict';

var Admin = {
  Dashboard: function () {

  },
  Login: function(){
    $(".login-form").submit(function(e) {

        e.preventDefault(); 
    
        var form = $(this);
        var url = form.attr('action');
    
        $.ajax({
              type: "POST",
              url: url,
              data: form.serialize(), 
              success: function(data){
                  console.log(data);
                    if( data == 'done' ){
                        window.location.href = 'templates.php';
                    }else{
                        alert('Login failed.');
                    }
              }
        });
    
    });
  },
  Layout: function () {
    get_sections();
    get_landings();
    get_sections_templates();

    $('body').on('click', '.copy-all-code', function(e){
        e.preventDefault();

        if (id_landing == '') {
          alert('You have to select a landing.');
          return false;
        }

        $('#all-code').val('');

        $.ajax({
          type: "POST",
          url: 'api/api.php',
          data: 'action=get_all_code&id_landing=' + id_landing + '&id_template=' + id_template,
          success: function (data) {
            $('#all-code').val(data);
          }
        });
    })

    $('body').on('keypress','.search_sections', function(e){
        if(e.keyCode == 13){
          
            var search  = $(this).val();
            
            $.ajax({
              type: "POST",
              url: 'api/api.php',
              data: 'action=search_sections&search='+search+'&id_group_sections='+$('#group-section').find("option:selected").val(),
              success: function(data){
                    console.log(data);
                    if($.trim(data)!='') $('#sections').html(data);
          
                    setTimeout(() => {
                      $('[data-popup="lightbox"]').fancybox();
                    },100);
                }
            });
        }
    });

    $('body').on('click', '.copy_code', function(e){
        e.preventDefault();
        $(this).next().slideToggle('fast');
    });

    $('body').on('click', '.load-more', function(e){
      e.preventDefault();
      console.warn($('#sections .support-ticket').length);
      $.ajax({
        type: "POST",
        url: "api/api.php",
        data: "action=get_sections&group_id=" + $('#group-section').find("option:selected").val() + "&row=" + $('#sections .support-ticket').length,
        success: function (data) {
          //console.log(data);
          $('#sections').append(data);
          
          setTimeout(() => {
            $('[data-popup="lightbox"]').fancybox();
          },100);
        },
        error: function (error) {
          console.log('Error: get_sections');
        }
      });
    });

    $(".ticket-thread").sortable({
      helper: 'clone',
      revert: 'invalid',
      start: function (e, ui) {
        ui.placeholder.height(ui.item.height());
        ui.placeholder.css('visibility', 'visible');
      },
      stop: function(){
        saveSectionPosition();
      }
    });

    $('#group-section').on('change', function (e) {
      get_sections($(this).find("option:selected").val());
    });

    $('body').on('click', '.add-section-to-template', function (e) {
      e.preventDefault();

      var id_section = $(this).attr('data-id-sections');

      if (id_landing == '') {
        alert('You have to select a landing.');
        return false;
      }

      $.ajax({
        type: "POST",
        url: 'api/api.php',
        data: 'action=add_section_to_template&id_landing=' + id_landing + '&id_section=' + id_section + '&id_template=' + id_template,
        success: function (data) {
          if( data == 'exist' ){
            alert('The section already was added.');
          }else{
            get_sections_templates();
            setTimeout(function(){
              saveSectionPosition();
            },100)
          }
        }
      });
    });

    $('body').on('click', '.select-landing', function (e) {
      e.preventDefault();
      id_landing = $(this).attr('data-id-landing');
      $('.layout-name').html('<a href="full_layout.php?it='+id_template+'&il='+id_landing+'" target="blank"><i class="os-icon os-icon-mail-18"></i> ' + $(this).attr('data-name-landing') + ' Layout</a>');

      // show sections
      get_sections_templates();
    });

    $('body').on('submit', '#modal-landing form', function (e) {
      e.preventDefault();
      $('.add-landing').click();
    });

    $('body').on('submit', '#modal-landing-update form', function (e) {
      e.preventDefault();
      $('.edit-landing').click();
    });

    $('body').on('click', '.add-landing', function (e) {
      e.preventDefault();

      var landing_name = $('#landing_name');
      //landing_name.val('');

      if ($.trim(landing_name.val()) == '') {
        alert('Name field is required.');
        return false;
      }

      $.ajax({
        type: "POST",
        url: 'api/api.php',
        data: 'action=add_landing&landing_name=' + landing_name.val() + '&id_template=' + id_template,
        success: function (data) {
          console.log(data);
          landing_name.val('');
          $('#modal-landing .close-modal').click();
          get_landings();
        }
      });
    });

    $('body').on('click', '.edit-landing-open-modal', function (e) {
      //e.preventDefault(); 
      var landing_name_edit = $('#landing_name_edit');
      landing_name_edit.val($.trim($(this).parent().parent().find('.current-landing-name').text()));

      var id_landing_temp = $(this).attr('data-id-landing');
      $('.edit-landing').attr('data-id-landing', id_landing_temp);
    });

    $('body').on('click', '.edit-landing', function (e) {
      e.preventDefault();

      var landing_name_edit = $('#landing_name_edit');

      if ($.trim(landing_name_edit.val()) == '') {
        alert('The Name field is required.');
        return false;
      }

      var id_landing_edit = $(this).attr('data-id-landing');

      $.ajax({
        type: "POST",
        url: 'api/api.php',
        data: 'action=update_landing&landing_name=' + landing_name_edit.val() + '&id_landing=' + id_landing_edit,
        success: function (data) {
          console.log(data);
          landing_name_edit.val('');
          $('#modal-landing-update .close-modal').click();
          get_landings();
        }
      });
    });

    $('body').on('click', '.delete-landing', function (e) {
      e.preventDefault();

      message = confirm("Are you sure to continue?");
      if( !message ) return false;

      var id_landing = $(this).attr('data-id-landing');

      $.ajax({
        type: "POST",
        url: 'api/api.php',
        data: 'action=delete_landing&id_landing=' + id_landing,
        success: function (data) {
          get_landings();
        }
      });
    });

    $('body').on('click', '.delete-sections-template', function (e) {
      e.preventDefault();

      var id_sections_template = $(this).attr('data-id-sections-template');

      $.ajax({
        type: "POST",
        url: 'api/api.php',
        data: 'action=remove_section_from_landing&id_sections_template=' + id_sections_template,
        success: function (data) {
          get_sections_templates();
        }
      });
    });

  }, // END Layout
  Templates: function () {

    call_projects_ready();
    call_all_projects();

    $('body').on('submit', '#form_add_new_template , #form_edit_template', function(e) {
        e.preventDefault(); 
    
        var form = $(this);
        var url = form.attr('action');
        // console.log(form);
        $.ajax({
              type: "POST",
              url: url,
              data: form.serialize(), 
              success: function(data){
                  console.log(data);
                  $('#form_add_new_template , #form_edit_template').find('.close-modal').click();
                  call_projects_ready();
                  call_all_projects();
              }
        });
        
    });

    $('body').on('click','.edit-project', function(e){
        var id_t = $(this).attr('data-id-template');
        $.ajax({
            type: "POST",
            url: 'api/api.php',
            data: 'action=get_edit_project_fields&id_template='+id_t, 
            success: function(data){
                console.log(data);
                $('#modal-template-update .modal-content').html(data);
            }
        });
    });

    $('body').on('click','.delete_template', function(e){
      e.preventDefault(); 

      message = confirm("Are you sure to continue?");
      if( !message ) return false;
      
      var id_template = $(this).attr('data-id-template');
      
      $.ajax({
          type: "POST",
          url: 'api/api.php',
          data: 'action=delete_template&id_template='+id_template, 
          success: function(data){
              //console.log(data);
              call_projects_ready();
              call_all_projects();
          }
      });
  })

  }, // END Templates
  Sections: function(){

      $("#form_add_new_sections, #form_add_new_group").submit(function(e) {
        e.preventDefault(); 
    
        var form = $(this);
        var url = form.attr('action');
    
        $.ajax({
              type: "POST",
              url: url,
              data: form.serialize(), 
              success: function(data){
                  console.log(data);
                    if( data == 'done' ){
                        window.location.reload();
                    }
              }
        });
    
    });

      $('body').on('click','.change-section-name', function(e){
        e.preventDefault(); 

        message = confirm("Are you sure to continue?");
        if( !message ) return false;
        
        var id_sections  = $(this).attr('data-id-sections');
        
        $.ajax({
            type: "POST",
            url: 'api/api.php',
            data: 'action=update-section-name&id_sections='+id_sections+'&name='+$(this).parent().find('#section_name').val(), 
            success: function(data){
                console.log(data);
                if( data == 'done' ){
                    //window.location.reload();
                    
                }else{
                    alert(data);
                }
            }
        });
        
    });

    $('body').on('click','.change-vs-code-section', function(e){
        e.preventDefault(); 

        message = confirm("Are you sure to continue?");
        if( !message ) return false;
        
        var id_sections  = $(this).attr('data-id-sections');
        
        $.ajax({
            type: "POST",
            url: 'api/api.php',
            data: 'action=update-vscode-section&id_sections='+id_sections+'&vs_code='+$(this).parent().find('textarea').val(), 
            success: function(data){
                console.log(data);
                if( data == 'done' ){
                    //window.location.reload();
                    
                }else{
                    alert(data);
                }
            }
        });
        
    });

    $('body').on('click','.change-css-code-section', function(e){
        e.preventDefault(); 

        message = confirm("Are you sure to continue?");
        if( !message ) return false;
        
        var id_sections  = $(this).attr('data-id-sections');
        
        $.ajax({
            type: "POST",
            url: 'api/api.php',
            data: 'action=update-csscode-section&id_sections='+id_sections+'&css_code='+$(this).parent().find('textarea').val(), 
            success: function(data){
                console.log(data);
                if( data == 'done' ){
                    //window.location.reload();
                    
                }else{
                    alert(data);
                }
            }
        });
        
    });

    $('body').on('change','#id_theme_sections', function(e){
      var id_theme  = $(this).val();
      var id_sections  = $(this).attr('data-id-sections');

      $.ajax({
          type: "POST",
          url: 'api/api.php',
          data: 'action=update-theme-sections&id_sections='+id_sections+'&id_theme='+id_theme, 
          success: function(data){
              console.log(data);
               if( data == 'done' ){
                   //window.location.reload();
                   
               }else{
                   alert(data);
               }
          }
      });
  });

    $('body').on('change','#id_group_sections', function(e){
        var id_group_sections  = $(this).val();
        var id_sections  = $(this).attr('data-id-sections');

        $.ajax({
            type: "POST",
            url: 'api/api.php',
            data: 'action=update-group-sections&id_sections='+id_sections+'&id_group_sections='+id_group_sections, 
            success: function(data){
                console.log(data);
                if( data == 'done' ){
                    //window.location.reload();
                    
                }else{
                    alert(data);
                }
            }
        });
    });


    $('body').on('click','.delete_sections', function(e){
      e.preventDefault(); 

      message = confirm("Are you sure to continue?");
      if( !message ) return false;
      
      var id_sections = $(this).attr('data-id-sections');
      
      $.ajax({
          type: "POST",
          url: 'api/api.php',
          data: 'action=remove_section&id_sections='+id_sections, 
          success: function(data){
              console.log(data);
               if( data == 'done' ){
                   window.location.reload();
               }
          }
      });
  })

  }, // END Templates
};

function call_projects_ready() {
  $.ajax({
    type: "POST",
    url: "api/api.php",
    data: "action=get_ready_projects",
    success: function (data) {
      //console.log(data);
      $('#projects-ready').find('.data').html(data);
      $('#modal-template .close-modal').click();
    },
    error: function (error) {
      console.log('Error: get_projects');
    }
  });
}

function call_all_projects() {
  $.ajax({
    type: "POST",
    url: "api/api.php",
    data: "action=get_all_projects",
    success: function (data) {
      //console.log(data);
      $('#projects-all').DataTable().destroy();
      $('#projects-all').find('.data').html(data);
      setTimeout(function () {
        //$('#projects-all').DataTable().draw();
      })
    },
    error: function (error) {
      console.log('Error: get_projects');
    }
  });
}

function saveSectionPosition() {
  id_section_t = '';
  j = 1;
  $('.sections_templates_block .support-ticket').each(function (e) {
    id_section_t = $(this).attr('data-id-section');

    $.ajax({
      type: "POST",
      url: 'api/api.php',
      data: 'action=section_order&id_section=' + id_section_t + '&order=' + j,
      success: function (data) {
        console.log(data);
      }
    });

    j++;

  });
}

function get_sections(group_id = '') {
  $.ajax({
    type: "POST",
    url: "api/api.php",
    data: "action=get_sections&group_id=" + group_id,
    success: function (data) {
      //console.log(data);
      $('#sections').html(data);
      setTimeout(() => {
        $('[data-popup="lightbox"]').fancybox();
      },100);
    },
    error: function (error) {
      console.log('Error: get_sections');
    }
  });
}

function get_sections_templates() {
  if (id_landing != '') {
    $.ajax({
      type: "POST",
      url: "api/api.php",
      data: "action=get_sections_templates&id_template=" + id_template + '&id_landing=' + id_landing,
      success: function (data) {
        $('#sections_templates').html(data);
        setTimeout(() => {
          $('[data-popup="lightbox"]').fancybox();
        },100);
      },
      error: function (error) {
        console.log('Error: get_sections');
      }
    });
  }
}

function get_landings() {
  $.ajax({
    type: "POST",
    url: "api/api.php",
    data: "action=get_landings&id_template=" + id_template,
    success: function (data) {
      //console.log(data);
      $('#landings').html(data);
    },
    error: function (error) {
      console.log('Error: get_landings');
    }
  });
}


$(document).ready(function () {

  console.warn('init');

  $('[data-popup="lightbox"]').fancybox({
      padding: 3
  });

  $('body').on('click', '.loadPage', function (e) {
    e.preventDefault();
    var tPage = $(this).attr('href');

    $.ajax({
      type: "POST",
      url: tPage,
      data: "",
      success: function (data) {
        //console.log(data);
        $('#sections').html(data);
      },
      error: function (error) {
        console.log('Error: get_projects');
      }
    });

  })

});

// ------------------------------------
// HELPER FUNCTIONS TO TEST FOR SPECIFIC DISPLAY SIZE (RESPONSIVE HELPERS)
// ------------------------------------

function is_display_type(display_type) {
  return $('.display-type').css('content') == display_type || $('.display-type').css('content') == '"' + display_type + '"';
}

function not_display_type(display_type) {
  return $('.display-type').css('content') != display_type && $('.display-type').css('content') != '"' + display_type + '"';
}

// Initiate on click and on hover sub menu activation logic
function os_init_sub_menus() {

  // INIT MENU TO ACTIVATE ON HOVER
  var menu_timer;
  $('.menu-activated-on-hover').on('mouseenter', 'ul.main-menu > li.has-sub-menu', function () {
    var $elem = $(this);
    clearTimeout(menu_timer);
    $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
    $elem.addClass('active');
  });

  $('.menu-activated-on-hover').on('mouseleave', 'ul.main-menu > li.has-sub-menu', function () {
    var $elem = $(this);
    menu_timer = setTimeout(function () {
      $elem.removeClass('active').closest('ul').removeClass('has-active');
    }, 30);
  });

  // INIT MENU TO ACTIVATE ON CLICK
  $('.menu-activated-on-click').on('click', 'li.has-sub-menu > a', function (event) {
    var $elem = $(this).closest('li');
    if ($elem.hasClass('active')) {
      $elem.removeClass('active');
    } else {
      $elem.closest('ul').find('li.active').removeClass('active');
      $elem.addClass('active');
    }
    return false;
  });
}

jQuery(function () {

  // #1. CHAT APP

  $('.floated-chat-btn, .floated-chat-w .chat-close').on('click', function () {
    $('.floated-chat-w').toggleClass('active');
    return false;
  });

  $('.message-input').on('keypress', function (e) {
    if (e.which == 13) {
      $('.chat-messages').append('<div class="message self"><div class="message-content">' + $(this).val() + '</div></div>');
      $(this).val('');
      var $messages_w = $('.floated-chat-w .chat-messages');
      $messages_w.scrollTop($messages_w.prop("scrollHeight"));
      $messages_w.perfectScrollbar('update');
      return false;
    }
  });

  $('.floated-chat-w .chat-messages').perfectScrollbar();

  // #2. CALENDAR INIT

  if ($("#fullCalendar").length) {
    var calendar, d, date, m, y;

    date = new Date();

    d = date.getDate();

    m = date.getMonth();

    y = date.getFullYear();

    calendar = $("#fullCalendar").fullCalendar({
      header: {
        left: "prev,next today",
        center: "title",
        right: "month,agendaWeek,agendaDay"
      },
      selectable: true,
      selectHelper: true,
      select: function select(start, end, allDay) {
        var title;
        title = prompt("Event Title:");
        if (title) {
          calendar.fullCalendar("renderEvent", {
            title: title,
            start: start,
            end: end,
            allDay: allDay
          }, true);
        }
        return calendar.fullCalendar("unselect");
      },
      editable: true,
      events: [{
        title: "Long Event",
        start: new Date(y, m, 3, 12, 0),
        end: new Date(y, m, 7, 14, 0)
      }, {
        title: "Lunch",
        start: new Date(y, m, d, 12, 0),
        end: new Date(y, m, d + 2, 14, 0),
        allDay: false
      }, {
        title: "Click for Google",
        start: new Date(y, m, 28),
        end: new Date(y, m, 29),
        url: "http://google.com/"
      }]
    });
  }

  // #3. FORM VALIDATION

  if ($('#formValidate').length) {
    $('#formValidate').validator();
  }


  // #5. DATATABLES

  if ($('#formValidate').length) {
    $('#formValidate').validator();
  }
  if ($('#projects-all').length) {
    $('#projects-all').DataTable({
      buttons: ['copy', 'excel', 'pdf']
    });
  }
  // #6. EDITABLE TABLES

  if ($('#editableTable').length) {
    $('#editableTable').editableTableWidget();
  }

  // #7. FORM STEPS FUNCTIONALITY

  $('.step-trigger-btn').on('click', function () {
    var btn_href = $(this).attr('href');
    $('.step-trigger[href="' + btn_href + '"]').click();
    return false;
  });

  // FORM STEP CLICK
  $('.step-trigger').on('click', function () {
    var prev_trigger = $(this).prev('.step-trigger');
    if (prev_trigger.length && !prev_trigger.hasClass('active') && !prev_trigger.hasClass('complete')) return false;
    var content_id = $(this).attr('href');
    $(this).closest('.step-triggers').find('.step-trigger').removeClass('active');
    $(this).prev('.step-trigger').addClass('complete');
    $(this).addClass('active');
    $('.step-content').removeClass('active');
    $('.step-content' + content_id).addClass('active');
    return false;
  });
  // END STEPS FUNCTIONALITY


  // #8. SELECT 2 ACTIVATION

  if ($('.select2').length) {
    $('.select2').select2();
  }

  // #9. CKEDITOR ACTIVATION

  if ($('#ckeditor1').length) {
    CKEDITOR.replace('ckeditor1');
  }

  // #10. CHARTJS CHARTS http://www.chartjs.org/

  if (typeof Chart !== 'undefined') {

    var fontFamily = '"Proxima Nova W01", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
    // set defaults
    Chart.defaults.global.defaultFontFamily = fontFamily;
    Chart.defaults.global.tooltips.titleFontSize = 14;
    Chart.defaults.global.tooltips.titleMarginBottom = 4;
    Chart.defaults.global.tooltips.displayColors = false;
    Chart.defaults.global.tooltips.bodyFontSize = 12;
    Chart.defaults.global.tooltips.xPadding = 10;
    Chart.defaults.global.tooltips.yPadding = 8;

    // init lite line chart if element exists

    if ($("#liteLineChart").length) {
      var liteLineChart = $("#liteLineChart");

      var liteLineGradient = liteLineChart[0].getContext('2d').createLinearGradient(0, 0, 0, 200);
      liteLineGradient.addColorStop(0, 'rgba(30,22,170,0.08)');
      liteLineGradient.addColorStop(1, 'rgba(30,22,170,0)');

      var chartData = [13, 28, 19, 24, 43, 49];

      if (liteLineChart.data('chart-data')) chartData = liteLineChart.data('chart-data').split(',');

      // line chart data
      var liteLineData = {
        labels: ["January 1", "January 5", "January 10", "January 15", "January 20", "January 25"],
        datasets: [{
          label: "Sold",
          fill: true,
          lineTension: 0.4,
          backgroundColor: liteLineGradient,
          borderColor: "#8f1cad",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "#fff",
          pointBackgroundColor: "#2a2f37",
          pointBorderWidth: 2,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: "#FC2055",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 5,
          data: chartData,
          spanGaps: false
        }]
      };

      // line chart init
      var myLiteLineChart = new Chart(liteLineChart, {
        type: 'line',
        data: liteLineData,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: false,
              ticks: {
                fontSize: '11',
                fontColor: '#969da5'
              },
              gridLines: {
                color: 'rgba(0,0,0,0.0)',
                zeroLineColor: 'rgba(0,0,0,0.0)'
              }
            }],
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
                max: 55
              }
            }]
          }
        }
      });
    }

    // init lite line chart V2 if element exists

    if ($("#liteLineChartV2").length) {
      var liteLineChartV2 = $("#liteLineChartV2");

      var liteLineGradientV2 = liteLineChartV2[0].getContext('2d').createLinearGradient(0, 0, 0, 100);
      liteLineGradientV2.addColorStop(0, 'rgba(40,97,245,0.1)');
      liteLineGradientV2.addColorStop(1, 'rgba(40,97,245,0)');

      var chartDataV2 = [13, 28, 19, 24, 43, 49, 40, 35, 42, 46];

      if (liteLineChartV2.data('chart-data')) chartDataV2 = liteLineChartV2.data('chart-data').split(',');

      // line chart data
      var liteLineDataV2 = {
        labels: ["1", "3", "6", "9", "12", "15", "18", "21", "24", "27"],
        datasets: [{
          label: "Balance",
          fill: true,
          lineTension: 0.35,
          backgroundColor: liteLineGradientV2,
          borderColor: "#2861f5",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "#2861f5",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 2,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "#FC2055",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          data: chartDataV2,
          spanGaps: false
        }]
      };

      // line chart init
      var myLiteLineChartV2 = new Chart(liteLineChartV2, {
        type: 'line',
        data: liteLineDataV2,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                fontSize: '10',
                fontColor: '#969da5'
              },
              gridLines: {
                color: 'rgba(0,0,0,0.0)',
                zeroLineColor: 'rgba(0,0,0,0.0)'
              }
            }],
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
                max: 55
              }
            }]
          }
        }
      });
    }

    // init lite line chart V2 if element exists

    if ($("#liteLineChartV3").length) {
      var liteLineChartV3 = $("#liteLineChartV3");

      var liteLineGradientV3 = liteLineChartV3[0].getContext('2d').createLinearGradient(0, 0, 0, 70);
      liteLineGradientV3.addColorStop(0, 'rgba(40,97,245,0.2)');
      liteLineGradientV3.addColorStop(1, 'rgba(40,97,245,0)');

      var chartDataV3 = [13, 28, 19, 24, 43, 49, 40, 35, 42, 46, 38];

      if (liteLineChartV3.data('chart-data')) chartDataV3 = liteLineChartV3.data('chart-data').split(',');

      // line chart data
      var liteLineDataV3 = {
        labels: ["", "FEB", "", "MAR", "", "APR", "", "MAY", "", "JUN", "", "JUL", ""],
        datasets: [{
          label: "Balance",
          fill: true,
          lineTension: 0.15,
          backgroundColor: liteLineGradientV3,
          borderColor: "#2861f5",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "#2861f5",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 2,
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "#FC2055",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: chartDataV3,
          spanGaps: false
        }]
      };

      // line chart init
      var myLiteLineChartV3 = new Chart(liteLineChartV3, {
        type: 'line',
        data: liteLineDataV3,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                fontSize: '10',
                fontColor: '#969da5'
              },
              gridLines: {
                color: 'rgba(0,0,0,0.0)',
                zeroLineColor: 'rgba(0,0,0,0.0)'
              }
            }],
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
                max: 55
              }
            }]
          }
        }
      });
    }

    // init line chart if element exists
    if ($("#lineChart").length) {
      var lineChart = $("#lineChart");

      // line chart data
      var lineData = {
        labels: ["1", "5", "10", "15", "20", "25", "30", "35"],
        datasets: [{
          label: "Visitors Graph",
          fill: false,
          lineTension: 0.3,
          backgroundColor: "#fff",
          borderColor: "#047bf8",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "#fff",
          pointBackgroundColor: "#141E41",
          pointBorderWidth: 3,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: "#FC2055",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 3,
          pointRadius: 5,
          pointHitRadius: 10,
          data: [27, 20, 44, 24, 29, 22, 43, 52],
          spanGaps: false
        }]
      };

      // line chart init
      var myLineChart = new Chart(lineChart, {
        type: 'line',
        data: lineData,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                fontSize: '11',
                fontColor: '#969da5'
              },
              gridLines: {
                color: 'rgba(0,0,0,0.05)',
                zeroLineColor: 'rgba(0,0,0,0.05)'
              }
            }],
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
                max: 65
              }
            }]
          }
        }
      });
    }

    // init donut chart if element exists
    if ($("#barChart1").length) {
      var barChart1 = $("#barChart1");
      var barData1 = {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [{
          label: "My First dataset",
          backgroundColor: ["#5797FC", "#629FFF", "#6BA4FE", "#74AAFF", "#7AAEFF", '#85B4FF'],
          borderColor: ['rgba(255,99,132,0)', 'rgba(54, 162, 235, 0)', 'rgba(255, 206, 86, 0)', 'rgba(75, 192, 192, 0)', 'rgba(153, 102, 255, 0)', 'rgba(255, 159, 64, 0)'],
          borderWidth: 1,
          data: [24, 42, 18, 34, 56, 28]
        }]
      };
      // -----------------
      // init bar chart
      // -----------------
      new Chart(barChart1, {
        type: 'bar',
        data: barData1,
        options: {
          scales: {
            xAxes: [{
              display: false,
              ticks: {
                fontSize: '11',
                fontColor: '#969da5'
              },
              gridLines: {
                color: 'rgba(0,0,0,0.05)',
                zeroLineColor: 'rgba(0,0,0,0.05)'
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                color: 'rgba(0,0,0,0.05)',
                zeroLineColor: '#6896f9'
              }
            }]
          },
          legend: {
            display: false
          },
          animation: {
            animateScale: true
          }
        }
      });
    }

    // init pie chart if element exists
    if ($("#pieChart1").length) {
      var pieChart1 = $("#pieChart1");

      // pie chart data
      var pieData1 = {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
        datasets: [{
          data: [300, 50, 100, 30, 70],
          backgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          hoverBackgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          borderWidth: 0
        }]
      };

      // -----------------
      // init pie chart
      // -----------------
      new Chart(pieChart1, {
        type: 'pie',
        data: pieData1,
        options: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 15,
              fontColor: '#3e4b5b'
            }
          },
          animation: {
            animateScale: true
          }
        }
      });
    }

    // -----------------
    // init donut chart if element exists
    // -----------------
    if ($("#donutChart").length) {
      var donutChart = $("#donutChart");

      // donut chart data
      var data = {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
        datasets: [{
          data: [300, 50, 100, 30, 70],
          backgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          hoverBackgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          borderWidth: 0
        }]
      };

      // -----------------
      // init donut chart
      // -----------------
      new Chart(donutChart, {
        type: 'doughnut',
        data: data,
        options: {
          legend: {
            display: false
          },
          animation: {
            animateScale: true
          },
          cutoutPercentage: 80
        }
      });
    }

    // -----------------
    // init donut chart if element exists
    // -----------------
    if ($("#donutChart1").length) {
      var donutChart1 = $("#donutChart1");

      // donut chart data
      var data1 = {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
        datasets: [{
          data: [300, 50, 100, 30, 70],
          backgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          hoverBackgroundColor: ["#5797fc", "#7e6fff", "#4ecc48", "#ffcc29", "#f37070"],
          borderWidth: 6,
          hoverBorderColor: 'transparent'
        }]
      };

      // -----------------
      // init donut chart
      // -----------------
      new Chart(donutChart1, {
        type: 'doughnut',
        data: data1,
        options: {
          legend: {
            display: false
          },
          animation: {
            animateScale: true
          },
          cutoutPercentage: 80
        }
      });
    }
  }

  // #11. MENU RELATED STUFF

  // INIT MOBILE MENU TRIGGER BUTTON
  $('.mobile-menu-trigger').on('click', function () {
    $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
    return false;
  });

  os_init_sub_menus();

  // #12. CONTENT SIDE PANEL TOGGLER

  $('.content-panel-toggler, .content-panel-close, .content-panel-open').on('click', function () {
    $('.all-wrapper').toggleClass('content-panel-active');
  });

  // #13. EMAIL APP 

  $('.more-messages').on('click', function () {
    $(this).hide();
    $('.older-pack').slideDown(100);
    $('.aec-full-message-w.show-pack').removeClass('show-pack');
    return false;
  });

  $('.ae-list').perfectScrollbar({
    wheelPropagation: true
  });

  $('.ae-list .ae-item').on('click', function () {
    $('.ae-item.active').removeClass('active');
    $(this).addClass('active');
    return false;
  });

  // CKEDITOR ACTIVATION FOR MAIL REPLY
  if (typeof CKEDITOR !== 'undefined') {
    CKEDITOR.disableAutoInline = true;
    if ($('#ckeditorEmail').length) {
      CKEDITOR.config.uiColor = '#ffffff';
      CKEDITOR.config.toolbar = [
        ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', '-', 'Link', 'Unlink', '-', 'About']
      ];
      CKEDITOR.config.height = 110;
      CKEDITOR.replace('ckeditor1');
    }
  }

  // EMAIL SIDEBAR MENU TOGGLER
  $('.ae-side-menu-toggler').on('click', function () {
    $('.app-email-w').toggleClass('compact-side-menu');
  });

  // EMAIL MOBILE SHOW MESSAGE
  $('.ae-item').on('click', function () {
    $('.app-email-w').addClass('forse-show-content');
  });

  if ($('.app-email-w').length) {
    if (is_display_type('phone') || is_display_type('tablet')) {
      $('.app-email-w').addClass('compact-side-menu');
    }
  }

  // #14. FULL CHAT APP
  function add_full_chat_message($input) {
    $('.chat-content').append('<div class="chat-message self"><div class="chat-message-content-w"><div class="chat-message-content">' + $input.val() + '</div></div><div class="chat-message-date">1:23pm</div><div class="chat-message-avatar"><img alt="" src="img/avatar1.jpg"></div></div>');
    $input.val('');
    var $messages_w = $('.chat-content-w');
    $messages_w.scrollTop($messages_w[0].scrollHeight);
  }

  $('.chat-btn a').on('click', function () {
    add_full_chat_message($('.chat-input input'));
    return false;
  });
  $('.chat-input input').on('keypress', function (e) {
    if (e.which == 13) {
      add_full_chat_message($(this));
      return false;
    }
  });

  // #15. CRM PIPELINE
  if ($('.pipeline').length) {
    // INIT DRAG AND DROP FOR PIPELINE ITEMS
    var dragulaObj = dragula($('.pipeline-body').toArray(), {}).on('drag', function () {}).on('drop', function (el) {}).on('over', function (el, container) {
      $(container).closest('.pipeline-body').addClass('over');
    }).on('out', function (el, container, source) {

      var new_pipeline_body = $(container).closest('.pipeline-body');
      new_pipeline_body.removeClass('over');
      var old_pipeline_body = $(source).closest('.pipeline-body');
    });
  }


  // #16. OUR OWN CUSTOM DROPDOWNS 
  $('.os-dropdown-trigger').on('mouseenter', function () {
    $(this).addClass('over');
  });
  $('.os-dropdown-trigger').on('mouseleave', function () {
    $(this).removeClass('over');
  });

  // #17. BOOTSTRAP RELATED JS ACTIVATIONS

  // - Activate tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // - Activate popovers
  $('[data-toggle="popover"]').popover();

  // #18. TODO Application

  // Tasks foldable trigger
  $('.tasks-header-toggler').on('click', function () {
    $(this).closest('.tasks-section').find('.tasks-list-w').slideToggle(100);
    return false;
  });

  // Sidebar Sections foldable trigger
  $('.todo-sidebar-section-toggle').on('click', function () {
    $(this).closest('.todo-sidebar-section').find('.todo-sidebar-section-contents').slideToggle(100);
    return false;
  });

  // Sidebar Sub Sections foldable trigger
  $('.todo-sidebar-section-sub-section-toggler').on('click', function () {
    $(this).closest('.todo-sidebar-section-sub-section').find('.todo-sidebar-section-sub-section-content').slideToggle(100);
    return false;
  });

  // Drag init
  if ($('.tasks-list').length) {
    // INIT DRAG AND DROP FOR Todo Tasks
    var dragulaTasksObj = dragula($('.tasks-list').toArray(), {
      moves: function moves(el, container, handle) {
        return handle.classList.contains('drag-handle');
      }
    }).on('drag', function () {}).on('drop', function (el) {}).on('over', function (el, container) {
      $(container).closest('.tasks-list').addClass('over');
    }).on('out', function (el, container, source) {

      var new_pipeline_body = $(container).closest('.tasks-list');
      new_pipeline_body.removeClass('over');
      var old_pipeline_body = $(source).closest('.tasks-list');
    });
  }

  // Task actions init

  // Complete/Done
  $('.task-btn-done').on('click', function () {
    $(this).closest('.draggable-task').toggleClass('complete');
    return false;
  });

  // Favorite/star
  $('.task-btn-star').on('click', function () {
    $(this).closest('.draggable-task').toggleClass('favorite');
    return false;
  });

  // Delete
  var timeoutDeleteTask;
  $('.task-btn-delete').on('click', function () {
    if (confirm('Are you sure you want to delete this task?')) {
      var $task_to_remove = $(this).closest('.draggable-task');
      $task_to_remove.addClass('pre-removed');
      $task_to_remove.append('<a href="#" class="task-btn-undelete">Undo Delete</a>');
      timeoutDeleteTask = setTimeout(function () {
        $task_to_remove.slideUp(300, function () {
          $(this).remove();
        });
      }, 5000);
    }
    return false;
  });

  $('.tasks-list').on('click', '.task-btn-undelete', function () {
    $(this).closest('.draggable-task').removeClass('pre-removed');
    $(this).remove();
    if (typeof timeoutDeleteTask !== 'undefined') {
      clearTimeout(timeoutDeleteTask);
    }
    return false;
  });

  // #19. Fancy Selector
  $('.fs-selector-trigger').on('click', function () {
    $(this).closest('.fancy-selector-w').toggleClass('opened');
  });

  // #20. SUPPORT SERVICE

  $('body').on('click', '.close-ticket-info', function () {
    $('.support-ticket-content-w').addClass('folded-info').removeClass('force-show-folded-info');
    return false;
  });

  $('body').on('click', '.show-ticket-info', function () {
    $('.support-ticket-content-w').removeClass('folded-info').addClass('force-show-folded-info');
    return false;
  });

  $('body').on('click', '.support-index .support-tickets .support-ticket', function () {
    $('.support-index').addClass('show-ticket-content');
    return false;
  });

  $('body').on('click', '.support-index .back-to-index', function () {
    $('.support-index').removeClass('show-ticket-content');
    return false;
  });

  // #21. Onboarding Screens Modal

  /*$('.onboarding-modal.show-on-load').modal('show');
  if ($('.onboarding-modal .onboarding-slider-w').length) {
    $('.onboarding-modal .onboarding-slider-w').slick({
      dots: true,
      infinite: false,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });
    $('.onboarding-modal').on('shown.bs.modal', function (e) {
      $('.onboarding-modal .onboarding-slider-w').slick('setPosition');
    });
  }*/

  // #22. Colors Toggler

  $('.floated-colors-btn').on('click', function () {
    if ($('body').hasClass('color-scheme-dark')) {
      $('.menu-w').removeClass('color-scheme-dark').addClass('color-scheme-light').removeClass('selected-menu-color-bright').addClass('selected-menu-color-light');
      $(this).find('.os-toggler-w').removeClass('on');
    } else {
      $('.menu-w, .top-bar').removeClass(function (index, className) {
        return (className.match(/(^|\s)color-scheme-\S+/g) || []).join(' ');
      });
      $('.menu-w').removeClass(function (index, className) {
        return (className.match(/(^|\s)color-style-\S+/g) || []).join(' ');
      });
      $('.menu-w').addClass('color-scheme-dark').addClass('color-style-transparent').removeClass('selected-menu-color-light').addClass('selected-menu-color-bright');
      $('.top-bar').addClass('color-scheme-transparent');
      $(this).find('.os-toggler-w').addClass('on');
    }
    $('body').toggleClass('color-scheme-dark');
    return false;
  });

  // #23. Autosuggest Search
  $('.autosuggest-search-activator').on('click', function () {
    var search_offset = $(this).offset();
    // If input field is in the activator - show on top of it
    if ($(this).find('input[type="text"]')) {
      search_offset = $(this).find('input[type="text"]').offset();
    }
    var search_field_position_left = search_offset.left;
    var search_field_position_top = search_offset.top;
    $('.search-with-suggestions-w').css('left', search_field_position_left).css('top', search_field_position_top).addClass('over-search-field').fadeIn(300).find('.search-suggest-input').focus();
    return false;
  });

  $('.search-suggest-input').on('keydown', function (e) {

    // Close if ESC was pressed
    if (e.which == 27) {
      $('.search-with-suggestions-w').fadeOut();
    }

    // Backspace/Delete pressed
    if (e.which == 46 || e.which == 8) {
      // This is a test code, remove when in real life usage
      $('.search-with-suggestions-w .ssg-item:last-child').show();
      $('.search-with-suggestions-w .ssg-items.ssg-items-blocks').show();
      $('.ssg-nothing-found').hide();
    }

    // Imitate item removal on search, test code
    if (e.which != 27 && e.which != 8 && e.which != 46) {
      // This is a test code, remove when in real life usage
      $('.search-with-suggestions-w .ssg-item:last-child').hide();
      $('.search-with-suggestions-w .ssg-items.ssg-items-blocks').hide();
      $('.ssg-nothing-found').show();
    }
  });

  $('.close-search-suggestions').on('click', function () {
    $('.search-with-suggestions-w').fadeOut();
    return false;
  });

  // #24. Element Actions
  $('.element-action-fold').on('click', function () {
    var $wrapper = $(this).closest('.element-wrapper');
    $wrapper.find('.element-box-tp, .element-box').toggle(0);
    var $icon = $(this).find('i');

    if ($wrapper.hasClass('folded')) {
      $icon.removeClass('os-icon-plus-circle').addClass('os-icon-minus-circle');
      $wrapper.removeClass('folded');
    } else {
      $icon.removeClass('os-icon-minus-circle').addClass('os-icon-plus-circle');
      $wrapper.addClass('folded');
    }
    return false;
  });




});